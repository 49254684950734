import React from 'react';

type StorageDataItem = {
    typeName: string;
    nbfile: number;
    sizetotal: number;
};

type DetailStockageProps = {
    data: StorageDataItem[];
};

const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const DetailStockagePieChart: React.FC<DetailStockageProps> = ({ data }) => {
    const totalSize = data.reduce((acc, item) => acc + item.sizetotal, 0);

    // Function to calculate the path for a pie slice
    const calculatePieSlicePath = (startAngle: number, endAngle: number, radius: number): string => {
        const startX = radius + radius * Math.cos(Math.PI * startAngle / 180);
        const startY = radius + radius * Math.sin(Math.PI * startAngle / 180);
        const endX = radius + radius * Math.cos(Math.PI * endAngle / 180);
        const endY = radius + radius * Math.sin(Math.PI * endAngle / 180);

        const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

        return `M${radius},${radius} L${startX},${startY} A${radius},${radius} 0 ${largeArcFlag} 1 ${endX},${endY} Z`;
    };

    const radius = 100;
    let currentAngle = 0;

    // Create pie slices based on storage data
    const paths = data.map((item, index) => {
        const sliceAngle = (item.sizetotal / totalSize) * 360;
        const path = calculatePieSlicePath(currentAngle, currentAngle + sliceAngle, radius);
        currentAngle += sliceAngle;
        return { 
            path, 
            color: `hsl(${(index * 360) / data.length}, 70%, 50%)`, 
            label: item.typeName,
            nbfile: item.nbfile,
            sizetotal: item.sizetotal
        };
    });

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
            <svg width="240" height="240" viewBox="0 0 240 240" style={{ display: 'block', margin: '0 auto' }}>
                {paths.map((slice, index) => (
                    <path key={index} d={slice.path} fill={slice.color} />
                ))}
            </svg>
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                {paths.map((slice, index) => (
                    <li key={index} style={{ color: slice.color, marginBottom: '5px' }}>
                        {slice.label} - {slice.nbfile} files, {formatBytes(slice.sizetotal)}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default DetailStockagePieChart;
