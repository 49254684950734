import React, { useState } from 'react';
import { ApiUser } from '../api/ApiUser';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLanguage } from '../components/language/LanguageContext';
import translate from '../components/language/Translate';


export default function ResetPasswordBox() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const { lang, changeLanguage } = useLanguage();


  // Critères de mot de passe
  const passwordCriteria = {
    length: password.length >= 8,
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
    digit: /\d/.test(password),
    specialChar: /[@$!%*?&]/.test(password),
  };

  const isPasswordValid = Object.values(passwordCriteria).every((criteria) => criteria);

  const handleConfirm = async () => {
    if (!isPasswordValid) {
      setError(translate('Le mot de passe ne respecte pas les critères requis.',lang));
      return;
    }

    if (password !== confirmPassword) {
      setError(translate('Les mots de passe ne correspondent pas.',lang));
      return;
    }

    try {
      await ApiUser.SetNewPasswordPassword(token!, password);
      alert(translate('Mot de passe réinitialisé avec succès.',lang));
      navigate('/login');
    } catch (error) {
      console.error(translate('Erreur lors de la réinitialisation du mot de passe:',lang), error);
      setError(translate("'Une erreur est survenue, veuillez réessayer.'",lang));
    }
  };

  return (
    <div className="login-box">
      <p className="text-Connexion">{translate("Réinitialisation du Mot de Passe",lang)}</p>

      {error && <p className="error-message">{error}</p>}
      <div className="scrollable-container">

      <input
        type="password"
        placeholder={translate("Nouveau mot de passe",lang)}
        className="input"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      

      <input
        type="password"
        placeholder={translate("Confirmer le mot de passe",lang)}
        className="input"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <ul className="password-requirements">
        <li className={passwordCriteria.length ? 'valid' : 'invalid'}>
          {translate("Au moins 8 caractères",lang)}
        </li>
        <li className={passwordCriteria.uppercase ? 'valid' : 'invalid'}>
          {translate("Une lettre majuscule",lang)}
        </li>
        <li className={passwordCriteria.lowercase ? 'valid' : 'invalid'}>
          {translate("Une lettre minuscule",lang)}
        </li>
        <li className={passwordCriteria.digit ? 'valid' : 'invalid'}>
          {translate("Un chiffre",lang)}
        </li>
        <li className={passwordCriteria.specialChar ? 'valid' : 'invalid'}>
            {translate("Un caractère spécial (@, $, !, %, *, ?, &)",lang)}
        </li>
      </ul>
      <button className="connect-button" onClick={handleConfirm} disabled={!isPasswordValid}>
        {translate("Confirmer",lang)}
      </button>
    </div>
    </div>

  );
}
