import React, { useState } from 'react';
import StockgeContainer from './StockgeContainer';
import vector from '../icon/Vector.svg';
import starFill from '../icon/Star Fill.svg'
import starStroke from '../icon/Star Stroke.svg'
import { ApiFile } from '../api/ApiFile';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../components/language/LanguageContext';
import translate from '../components/language/Translate';


interface Tag {
  name: string;
  value: string;
  id_: string;
}

interface Document {
  id: string;
  favori: boolean;
  nom: string;
  date: string;
  typeName: string;
  tags: Tag[];
}

interface DocumentSideBarProps {
  documents: Document[];
  setDocuments: React.Dispatch<React.SetStateAction<Document[]>>;
}


const DocumentSideBar: React.FC<DocumentSideBarProps> = ({ documents, setDocuments }) => {
  const { lang, changeLanguage } = useLanguage();

  const truncateName = (name: string, maxLength: number) => {
    return name.length > maxLength ? name.slice(0, maxLength) + '...' : name;
  };
  const navigate = useNavigate();


  const toggleFavori = (id: string) => {
    ApiFile.ChangeFavorie(id);
    setDocuments(prevDocuments =>
      prevDocuments.map(doc =>
        doc.id === id ? { ...doc, favori: !doc.favori } : doc
      )
    );
  };

  const favoris = documents.filter(doc => doc.favori);

  return (
    <div className="DocumentSideBar">
      <div className="quickAccess">{translate("Accès Rapide",lang)}</div>
      <StockgeContainer />
      <hr className="divider-document" />
      <ul className="documentList">
        {favoris.map((doc) => (
          <li key={doc.id} className="documentItem">
            <div className="containerwith-logo">
              <img src={vector} alt="Documents" className="documentIcon" />
              <div className="documentInfo">
                <div className="documentName" onClick={() => navigate('/documents/'+doc.id)}>{truncateName(doc.nom, 15)}</div>
                <div className="documentDate">{doc.date}</div>
              </div>
            </div>
            <div className="Favorite-container">
              <button className="favoriButton" onClick={() => toggleFavori(doc.id)}>
                {doc.favori ? (
                  <img src={starFill} alt="Filled Star" className="favoriIcon" />
                ) : (
                  <img src={starStroke} alt="Outlined Star" className="favoriIcon" />
                )}
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DocumentSideBar;
