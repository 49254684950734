import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import du hook useNavigate
import { ApiUser } from '../api/ApiUser';
import { useLanguage } from '../components/language/LanguageContext';
import translate from '../components/language/Translate';

export default function LoginBox() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState(''); // Ajout du champ facultatif pour le téléphone
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate(); // Initialisation de useNavigate
  const { lang, changeLanguage } = useLanguage();


  const passwordCriteria = {
    length: password.length >= 8,
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
    digit: /\d/.test(password),
    specialChar: /[@$!%*?&]/.test(password),
  };

  const isPasswordValid = Object.values(passwordCriteria).every((criteria) => criteria);

  const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleRegister = async () => {
    setError(null);

    if (!isEmailValid) {
      setError(translate('Veuillez entrer une adresse email valide.',lang));
      return;
    }

    if (!isPasswordValid) {
      setError(
        translate('Le mot de passe ne respecte pas les critères requis : au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial.',lang)
      );
      return;
    }

    if (password !== confirmPassword) {
      setError(translate('Les mots de passe ne correspondent pas.',lang));
      return;
    }

    try {
      await ApiUser.register(firstName, lastName, email, password, phone);
      navigate('/confirmation-mail');
    } catch (error) {
      console.error(translate('Erreur lors de l`inscription :',lang), error);
      setError(translate("Une erreur est survenue. Veuillez réessayer.",lang));
    }
  };

  return (
    <div className="login-box">
      <p className="text-Connexion">{translate("Inscription",lang)}</p>
      <p className="signup-text">
        {translate("Vous avez déjà un compte ?",lang)}{' '}
        <button className="signup-button" onClick={() => navigate('/login')}>
          {translate("Connectez-vous",lang)}
        </button>
      </p>

      {error && <p className="error-message">{error}</p>}

      {/* Inputs wrapped in a scrollable container */}
      <div className="scrollable-container">
      <div className="inputs-container">
        <input
          type="text"
          placeholder={translate("Nom",lang)}
          className="input"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <input
          type="text"
          placeholder={translate("Prénom",lang)}
          className="input"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <input
          type="email"
          placeholder={translate("Email",lang)}
          className="input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="text"
          placeholder={translate("Téléphone (facultatif)",lang)}
          className="input"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <input
          type="password"
          placeholder={translate("password",lang)}
          className="input"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder={translate("Confirmation du mot de passe",lang)}
          className="input"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </div>

      <ul className="password-requirements">
        <li className={passwordCriteria.length ? 'valid' : 'invalid'}>
          {translate("Au moins 8 caractères",lang)}
        </li>
        <li className={passwordCriteria.uppercase ? 'valid' : 'invalid'}>
          {translate("Une lettre majuscule",lang)}
        </li>
        <li className={passwordCriteria.lowercase ? 'valid' : 'invalid'}>
          {translate("Une lettre minuscule",lang)}
        </li>
        <li className={passwordCriteria.digit ? 'valid' : 'invalid'}>
          {translate("Un chiffre",lang)}
        </li>
        <li className={passwordCriteria.specialChar ? 'valid' : 'invalid'}>
          {translate("Un caractère spécial (@, $, !, %, *, ?, &)",lang)}
        </li>
      </ul>

      <button className="connect-button" onClick={handleRegister} disabled={!isPasswordValid || !isEmailValid}>
        {translate("S'inscrire",lang)}
      </button>
      </div>

    </div>
  );
}
