import React, { useState, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../components/language/LanguageContext';
import translate from '../components/language/Translate';

type Subscription = {
  name: string;
  price: string;
  description: string;
  detailedDescription: string;
  advantages: string[];
  keyFeatures: string[];
};

const subscriptions: Subscription[] = [
  {
    name: 'Abonnement 1',
    price: 'Gratuit €/mois',
    description: 'Plan de base avec des fonctionnalités limitées.',
    detailedDescription:
      'Ce plan inclut des fonctionnalités de base et convient aux particuliers ou aux petites entreprises qui souhaitent débuter avec notre service.',
    advantages: [
      'Avantage 1 pour l’Abonnement 1',
      'Avantage 2 pour l’Abonnement 1',
      'Avantage 3 pour l’Abonnement 1',
    ],
    keyFeatures: [
      'Jeu de fonctionnalités de base',
      'Options de support limitées',
      '10 Go mise à disposition',
      'Mises à jour mensuelles et corrections de bugs',
    ],
  },
  {
    name: 'Abonnement 2',
    price: '9,99 €/mois',
    description: 'Plan standard avec des fonctionnalités supplémentaires.',
    detailedDescription:
      'Passez à notre plan standard pour des fonctionnalités supplémentaires et un support amélioré. Parfait pour les entreprises en croissance.',
    advantages: [
      'Avantage 1 pour l’Abonnement 2',
      'Avantage 2 pour l’Abonnement 2',
      'Avantage 3 pour l’Abonnement 2',
    ],
    keyFeatures: [
      'Jeu de fonctionnalités avancées',
      'Support prioritaire par email',
      '50 Go mise à disposition',
    ],
  },
  {
    name: 'Abonnement 3',
    price: '19,99 €/mois',
    description: 'Plan Premium avec toutes les fonctionnalités incluses.',
    detailedDescription:
      'Débloquez toutes les fonctionnalités avec notre plan Premium. Idéal pour les entreprises recherchant une fonctionnalité avancée et un support prioritaire.',
    advantages: [
      'Avantage 1 pour l’Abonnement 3',
      'Avantage 2 pour l’Abonnement 3',
      'Avantage 3 pour l’Abonnement 3',
    ],
    keyFeatures: [
      'Ensemble de fonctionnalités complètes',
      'Support prioritaire 24/7',
      'Analyses avancées et rapports',
      'Gestionnaire de compte dédié',
      'Go illimités',
    ],
  },
  {
    name: 'Abonnement 4',
    price: '29,99 €/mois',
    description: 'Plan ultime avec des fonctionnalités exclusives et support.',
    detailedDescription:
      'Accédez à notre plan ultime pour des fonctionnalités exclusives, un support prioritaire et une gestion de compte dédiée. Parfait pour les besoins de niveau entreprise.',
    advantages: [
      'Avantage 1 pour l’Abonnement 4',
      'Avantage 2 pour l’Abonnement 4',
      'Avantage 3 pour l’Abonnement 4',
    ],
    keyFeatures: [
      'Jeu de fonctionnalités exclusives',
      'Support premium 24/7',
      'Accès aux fonctionnalités en bêta',
      'Tableau de bord analytique personnalisable',
      'Go illimités',
    ],
  },
];

const Subscription: React.FC = () => {
  const [selectedSubscription, setSelectedSubscription] = useState<number>(0);
  const navigate = useNavigate();
  const learnMoreRef = useRef<HTMLDivElement>(null);
  const { lang } = useLanguage(); // Access current language

  const translatedSubscriptions = useMemo(() => {
    return subscriptions.map(subscription => ({
      name: translate(subscription.name, lang),
      price: translate(subscription.price, lang),
      description: translate(subscription.description, lang),
      detailedDescription: translate(subscription.detailedDescription, lang),
      advantages: subscription.advantages.map(advantage => translate(advantage, lang)),
      keyFeatures: subscription.keyFeatures.map(feature => translate(feature, lang)),
    }));
  }, [lang]);

  const handleConfirmButtonClick = () => {
    navigate('/documents');
  };

  const handleCrossButtonClick = () => {
    navigate('/documents');
  };

  const handleGetStartedClick = (index: number) => {
    console.log(`Commencer avec l’Abonnement ${index}`);
  };

  const handleLearnMoreClick = (index: number) => {
    console.log(`En savoir plus sur l’Abonnement ${index}`);
    setSelectedSubscription(index);
    if (learnMoreRef.current) {
      learnMoreRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const renderSubscription = (subscription: Subscription, index: number) => {
    return (
      <div
        key={index}
        className={`subscription ${
          selectedSubscription === index ? 'selected' : ''
        } ${index % 2 === 0 ? 'slide-in-left' : 'slide-in-right'}`}
        onClick={() => setSelectedSubscription(index)}
      >
        <div>
          <div className="subscription-name">{subscription.name}</div>
          <hr className="divider" />
          <div className="price">{subscription.price}</div>
          <hr className="divider" />
          <div>{subscription.description}</div>
          <div>{subscription.detailedDescription}</div>
          <hr className="divider" />
          <ul className="key-features">
            {subscription.keyFeatures.map((feature, i) => (
              <li key={i} className="key-feature-item">
                {feature}
              </li>
            ))}
          </ul>
        </div>
        <div className="button-container">
          {/* <button
            className="button"
            onClick={() => handleGetStartedClick(index)}
          >
            {translate('Commencer', lang)}
          </button> */}
        </div>
      </div>
    );
  };

  return (
    <div className="page">
      <div className="cross-button" onClick={handleCrossButtonClick}>
        &#x2715;
      </div>
      <div className="txt">{translate('Combien ça coûte ?', lang)}</div>
      <div className="title">{translate('Des abonnements pour tous les besoins', lang)}</div>
      <div className="txtBis">
        {translate('Chaque plan d’abonnement pour chaque budget !(les prix sont ici à titre indicatif)', lang)}
      </div>
      <div className="subscription-container">
        {translatedSubscriptions.map((subscription, index) =>
          renderSubscription(subscription, index)
        )}
      </div>
      <div className="confirm-button" onClick={handleConfirmButtonClick}>
        {translate("J'ai compris", lang)}
      </div>
    </div>
  );
};

export default Subscription;
