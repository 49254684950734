import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiUser } from '../api/ApiUser';
import { useLanguage } from '../components/language/LanguageContext';
import translate from '../components/language/Translate';

// Assuming you have the logo in the 'assets' folder or public folder
import logo from '../icon/Alfred_lineWhite.png'

export default function LoginBox() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const { lang, changeLanguage } = useLanguage();

  const handleLogin = () => {
    setError(null);
    if (email && password) {
      ApiUser.login(email, password)
        .then((response) => {
          console.log('response:', response);
          if (!response.token) {
            navigate('/login2FA');
          } else {
            navigate('/documents');
          }
        })
        .catch((error) => {
          // Check if error has a message property, otherwise use a default message
          setError(error.message || 'Une erreur s\'est produite lors de la connexion.');
        });
    } else {
      setError(translate("Veuillez remplir tous les champs.",lang));
    }
  };
  const handlePasswordReset = () => {
    // Expression régulière pour valider le format d'un email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setError(null)
    if (!email) {
      setError(translate(`Veuillez entrer votre adresse email.`,lang));
      return;
    }
  
    if (!emailRegex.test(email)) {
      setError(translate("Veuillez entrer une adresse email valide.",lang));
      return;
    }
  
    setShowModal(false);
    ApiUser.resetPassword(email);
    alert('Nous vous avons envoyé un mail pour réinitialiser votre mot de passe.');
    setError(null)
  };
  

  return (
    <div className="login-box">
      <p className="text-Connexion">{translate('Se connecter',lang)}</p>
      <p className='signup-text'>
        {translate(`Vous n'avez pas de compte ?`,lang)}{' '}
        <button className="signup-button" onClick={() => navigate('/register')}>
          {translate(`Inscrivez-vous`,lang)}
        </button>
      </p>
      {error && <p className="error-message">{error}</p>}
      <input
        type="email"
        placeholder="email@adresse.com"
        className="input"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder={translate("password",lang)}
        className="input"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button className="connect-button" onClick={handleLogin}>
        {translate("Se connecter",lang)}
      </button>
      <button className="signup-ForgetPassword" onClick={() => {setShowModal(true);setError(null)}}>
        {translate(`Mot de passe oublié ?`,lang)}
      </button>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            {/* <img src={logo} alt="Logo" className="modal-logo" /> */}
            <h2>{translate("Réinitialiser votre mot de passe",lang)}</h2>
            {error && <p className="error-message">{error}</p>}
            <input
              type="email"
              placeholder={translate(`Entrez votre email`,lang)}
              className="input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button className="connect-button" onClick={handlePasswordReset}>
              {translate("Réinitialiser le mot de passe",lang)}
            </button>
            <button className="close-modal-button" onClick={() => {setShowModal(false);setError(null)}}>
              {translate("Fermer",lang)}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
