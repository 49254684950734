import React from 'react';

import Confirm2faBoxCode from './Confirm2faBoxCode';
import { useLanguage } from '../components/language/LanguageContext';
import translate from '../components/language/Translate';


export default function Confirmation2faBox() {
  const { lang, changeLanguage } = useLanguage();

    return (
        <div className="login-box">
            <p className="text-Connexion">{translate("Confirmation 2FA",lang)}</p>
            <p className='resend-text'>
                {translate("Veuillez entrer le code que nous vous avons envoyé.",lang)}
            </p>
            <Confirm2faBoxCode />

        </div>
    );
}