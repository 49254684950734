import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBack from '../icon/arrow-back.svg';
import { ApiFile } from '../api/ApiFile';
import trash from '../icon/Trash.svg'
import Plus from '../icon/Plus.svg'
import translate from '../components/language/Translate';
import { useLanguage } from '../components/language/LanguageContext';


interface Tag {
  value: string;
  name: string;
}

interface Data {
  name: string;
  type: string;
  size: number;
  tags: Tag[];
}

export default function DocumentDetail() {
  const { lang, changeLanguage } = useLanguage();

  const navigate = useNavigate();
  const { id } = useParams();
  const [fileType, setFileType] = useState<string>('test');
  const [isEditing, setIsEditing] = useState(false); // State to track if in editing mode
  const [newTag, setNewTag] = useState<Tag>({
    value: '',
    name: ''
  })

  const [details, setDetails] = useState<Data>({
    name: '',
    type: '',
    size: 0,
    tags: []
  }); // State for document details
  const [InputDetails, setInputDetails] = useState<Data>({
    name: '',
    type: '',
    size: 0,
    tags: []
  }); // State for document details

  const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  useEffect(() => {
    const fetchStorageInfo = async () => {
      if (id) {
        const DocumentDetail = await ApiFile.getFilebyId(id);
        const mimeType = DocumentDetail.fileType;
        if (mimeType.includes('image')) {
          setFileType('image');
        } else if (mimeType.includes('pdf')) {
          setFileType('pdf');
        } else {
          setFileType('unsupported');
        }
        const data: Data = {
          type: DocumentDetail.typeName,
          name: DocumentDetail.name,
          size: DocumentDetail.size,
          tags: DocumentDetail.tags,
        }
        setDetails(data);
        setInputDetails(data);
      }
    };

    fetchStorageInfo();
  }, [id]);

  if (!id) {
    return <div>{translate("Error: Document ID is missing.",lang)}</div>;
  }

  const handleAddTag = () => {
    if (newTag.name && newTag.value) {
      // Create a new array with the existing tags and the new tag
      const updatedTags = [...InputDetails.tags, { name: newTag.name, value: newTag.value }];
      
      // Update the InputDetails with the new tags array
      setInputDetails({ ...InputDetails, tags: updatedTags });
  
      // Clear the newTag state to reset the input fields
      setNewTag({ name: '', value: '' });
    }
  };

  const handleDeleteToggle = () => {
    if (isEditing) {
      console.log(details)
      const data = details;
      setInputDetails(data);
      setIsEditing((prev) => !prev);
      setNewTag({ name: '', value: '' });
    } else {
      try {
        ApiFile.deleteFile(id);
        navigate('/documents')
      } catch (error) {
        alert('erreur lors de la supression')
      }
    }
  }

  const handleEditToggle = async () => {
    if (isEditing) {
      console.log(InputDetails)
      setDetails(InputDetails)
      console.log(details)
      try {
        const body = {
          fileId: id,
          name: InputDetails.name,
          tags: InputDetails.tags
        }
        console.log('body: ', body);
        await ApiFile.PutFile(body)
        alert(translate("document modifié avec succès",lang))
      } catch (error) {
        alert(translate("le document n'a pas pu être modifié",lang))
      }
      
      setNewTag({ name: '', value: '' });
    }
    setIsEditing((prev) => !prev);
  };

  const handleInputChange = (key: number, newName: string) => {
    if (key == -1) {
      const updatedDetails = {
        ...InputDetails,
        name: newName
      };
      setInputDetails(updatedDetails);

    } else {
      const updatedDetails = { ...InputDetails };
      console.log(key, newName);
      // Update the specific tag's name by its index (key)
      updatedDetails.tags = updatedDetails.tags.map((tag, index) =>
        index === key ? { ...tag, value: newName } : tag
      );
      setInputDetails(updatedDetails);
    }
  };

  const handleDeleteTags = (index: number) => {
    // Make a copy of the current tags
    const updatedTags = [...InputDetails.tags];
    // Remove the tag at the specified index
    updatedTags.splice(index, 1);
    // Update the state with the new list of tags
    const updatedDetails = {
      ...InputDetails,
      tags: updatedTags
    };

    // Set the updated input details in state
    setInputDetails(updatedDetails);
  };

  return (
    <div className="document-detail">
      <div className="back-button" onClick={() => { navigate('/documents') }}>
        <img src={ArrowBack} alt="Back Arrow" />
      </div>

      <div className="file-display">
        {fileType === 'image' && (
          <img className='file-display-img' src={'https://alfred-app.fr/api-file/file/download/get/id/' + id} alt="Document Image" />
        )}
        {fileType === 'pdf' && (
          <iframe
            src={'https://alfred-app.fr/api-file/file/download/get/id/' + id}
            title="PDF Document"
            className="document-pdf"
            frameBorder="0"
          ></iframe>
        )}
        {fileType === 'unsupported' && <div>{translate("File type not supported",lang)}</div>}
      </div>

      {/* Displaying the mocked data */}
      <div className="document-info">
        <div className="header">
          <div>
            <strong className='field-label'>{translate("name :",lang)}</strong>
            <span className="field-value">
              {isEditing ? (
                <input
                  type="text"
                  value={InputDetails.name}
                  onChange={(e) => handleInputChange(-1, e.target.value)}
                />
              ) : (
                details.name
              )}
            </span>
          </div>
          <div><strong className='field-label'>{translate("file type :",lang)}</strong> <span className="field-value">{InputDetails.type}</span></div>
          <div><strong className='field-label'>{translate("Size :",lang)}</strong> <span className="field-value">{formatBytes(InputDetails.size)}</span></div>
        </div>

        {/* Mapping through the details */}
        <div className="document-fields">
  {InputDetails.tags.map((tag, index) => (
    <div className="field-row" key={tag.name}>
      <div className="field-label">
        {tag.name.replace(/_/g, ' ')} :
      </div>
      <div className="field-value">
        {isEditing ? (
          <input
            type="text"
            value={tag.value}
            onChange={(e) => handleInputChange(index, e.target.value)}
          />
        ) : (
          tag.value
        )}
        {isEditing && (
          <button className="Trash-Button-tag" onClick={() => handleDeleteTags(index)}>
            <img src={trash} alt="Delete Tag" className="favoriIcon" />
          </button>
        )}
      </div>
    </div>
  ))}
  {isEditing && (
    <div className="new-tag-row">
      <input
        type="text"
        placeholder="Tag name"
        value={newTag.name}
        onChange={(e) => setNewTag({ ...newTag, name: e.target.value })}
      />
      <input
        type="text"
        placeholder="Tag value"
        value={newTag.value}
        onChange={(e) => setNewTag({ ...newTag, value: e.target.value })}
      />
      <button className="add-Button-tag" onClick={handleAddTag}>
        <img className='add-Button-tag-icon' src={Plus} alt="Add Tag" />
      </button>
    </div>
  )}
</div>
        <div className="button-container">
          <button className="delete-button" onClick={handleDeleteToggle}>
            {isEditing ? 'Anuler' : 'Suprimer'}
          </button>
          <button className="change-button" onClick={handleEditToggle}>
            {isEditing ? 'Enregistrer' : 'Changer'}
          </button>
        </div>
      </div>
    </div>
  );
}
