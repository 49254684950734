import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../components/language/LanguageContext';
import translate from '../components/language/Translate';


export default function Offer() {
  const navigate = useNavigate();
    const { lang, changeLanguage } = useLanguage();

  const [showGoogleFormMessage, setShowGoogleFormMessage] = useState(false); // État pour afficher le message du Google Form

  const handleArrowClick = () => {
    setShowGoogleFormMessage(!showGoogleFormMessage); // Affiche le message Google Form
  };

  const handleDiscoverClick = () => {
    navigate('/subscription'); // Redirige vers l'abonnement
  };

  return (
    <div className="offer-Container">
      <button className="arrow-button" onClick={handleArrowClick}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 19L8 12L15 5"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>

      <div className="offer-box">
        {showGoogleFormMessage ? (
          <>
            <div className="offer-text">{translate("Merci pour votre intérêt !",lang)}</div>
            <div>
              {translate("Veuillez remplir le formulaire Google pour participer à notre Bêta. Cliquez sur le bouton ci-dessous pour y accéder.",lang)}
            </div>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSfomne1e8kWF8mrSpjm5VSqGQ6w_gQKO6iLBJ2V8ifPWDTpjQ/viewform" // Remplacez par l'URL réelle du formulaire Google
              target="_blank"
              rel="noopener noreferrer"
              className="discover-button"
            >
              {translate("Remplir le formulaire",lang)}
            </a>
          </>
        ) : (
          <>
            <div className="offer-text">{translate("Bienvenue dans notre Bêta !",lang)}</div>
            <div>
              {translate("Nous travaillons encore sur nos offres d'abonnement. Cliquez ci-dessous pour en savoir plus et rester informé des nouveautés.",lang)}
            </div>
            <button className="discover-button" onClick={handleDiscoverClick}>
              {translate("En savoir plus",lang)}
            </button>
          </>
        )}
      </div>

      <button className="arrow-button" onClick={handleArrowClick}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 5L16 12L9 19"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );
}
