import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from "../icon/Alfred_lineWhite.png";
import files from "../icon/Files.svg";
import bounce from "../icon/Bounce.svg";
import user from "../icon/User.svg";
import Cookies from 'js-cookie';
import logout from "../icon/logout.svg"
import { useLanguage } from '../components/language/LanguageContext';
import translate from '../components/language/Translate';


export default function Sidebar() {
  const navigate = useNavigate();
  const { lang, changeLanguage } = useLanguage();

  const location = useLocation();

  const getFirstPathSegment = (pathname: string) => {
    const segments = pathname.split('/').filter(Boolean);
    return segments[0] || '';
  };

  const [selected, setSelected] = useState('');

  useEffect(() => {
    const firstSegment = getFirstPathSegment(location.pathname);

    if (firstSegment === 'documents') {
      setSelected('Documents');
    } else if (firstSegment === 'scenarios') {
      setSelected('Scénarios');
    } else if (firstSegment === 'profile') {
      setSelected('Mon Compte');
    }
  }, [location.pathname]);

  const handleNavigation = (path: string, label: string) => {
    setSelected(label);
    navigate(path);
  };

  const handleLogout = () => {
    Cookies.remove('authToken');
    Cookies.remove('refreshToken');
    Cookies.remove('idUser');
    // navigate('/')
    navigate('/login'); // Redirect to login page or home


    // navigate('/login'); // Redirect to login page
  };

  return (
    <>
      <div className="sidebar">
        <div className="logo" onClick={() => navigate('/documents')}>
          <button className="logo-btn">
            <img src={logo} alt="Logo" className="logo-image" />
          </button>
        </div>

        <ul className="nav-links">
          <li>
            <button
              className={`nav-button ${selected === 'Documents' ? 'active' : ''}`}
              onClick={() => handleNavigation('/documents', 'Documents')}
            >
              <img src={files} alt="Documents" />
              Documents
            </button>
          </li>
          <li>
            <button
              className={`nav-button ${selected === 'Scénarios' ? 'active' : ''}`}
              onClick={() => handleNavigation('/scenarios', 'Scénarios')}
            >
              <img src={bounce} alt="Scénarios" />
              {translate("Scénarios",lang)}
            </button>
          </li>
        </ul>

        <hr className="sidebar-divider" />

        <ul className="nav-links">
          <li>
            <button
              className={`nav-button ${selected === 'Mon Compte' ? 'active' : ''}`}
              onClick={() => handleNavigation('/profile', 'Mon Compte')}
            >
              <img src={user} alt="Mon Compte" />
              {translate("Mon Compte",lang)}
            </button>
          </li>
        </ul>

        {/* Logout Button for Desktop */}
        <button className="logout-button" onClick={handleLogout}>
  <img src={logout} alt="Logout Icon" className="logout-icon" />
  {translate("Se déconnecter",lang)}
</button>
      </div>

      {/* Mobile Navigation */}
      <div className="mobile-nav">
        <button onClick={() => handleNavigation('/documents', 'Documents')}>
          <img src={files} alt="Documents" />
          <span>Documents</span>
        </button>
        <button onClick={() => handleNavigation('/scenarios', 'Scénarios')}>
          <img src={bounce} alt="Scénarios" />
          <span>{translate("Scénarios",lang)}</span>
        </button>
        <button onClick={() => handleNavigation('/profile', 'Mon Compte')}>
          <img src={user} alt="Mon Compte" />
          <span>{translate("Mon Compte",lang)}</span>
        </button>
        {/* Logout Button for Mobile */}
        <button className="logout-button" onClick={handleLogout}>
        <img src={logout} alt="Logout Icon" className="logout-icon" />
          <span>{translate("Se déconnecter",lang)}</span>
        </button>
      </div>
    </>
  );
}
