import React, { useState, useEffect } from 'react';
import backArrow from '../../icon/arrow-back.svg';
import check from '../../icon/Check.svg';
import plus from "../../icon/Plus.svg";
import trash from "../../icon/Trash.svg";
import { useNavigate } from 'react-router-dom';
import { ApiFile } from '../../api/ApiFile';
import { useLanguage } from '../../components/language/LanguageContext';
import translate from '../../components/language/Translate';

// Define the type for each item in dropdownCheckboxes
type DropdownCheckbox = {
  dropdownValue: string;
  isChecked: boolean;
  id?: number | string;
};

type FileType = {
  _id: number;
  nom: string;
};

export interface File {
  id?: number | string;
  isNeeded: boolean;
  name: string;
}


export default function NewScenario() {
  const { lang, changeLanguage } = useLanguage();

  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [dropdownCheckboxes, setDropdownCheckboxes] = useState<DropdownCheckbox[]>([
    { dropdownValue: '', isChecked: false },
  ]);
  const [types, setTypes] = useState<FileType[]>([]);

  useEffect(() => {
    const fetchType = async () => {
      const type: FileType[] = await ApiFile.getFileType();
      console.log(type)
      setTypes(type);
    }

    fetchType();
  }, []);


  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/scenarios');
  };

  const handleDropdownChange = (index: number, value: string) => {
    const selectedType = types.find((type) => type.nom === value);
    const updatedDropdownCheckboxes = dropdownCheckboxes.map((item, i) =>
      i === index
        ? { ...item, dropdownValue: value, id: selectedType?._id } // Ajoute l'ID du type sélectionné
        : item
    );
    setDropdownCheckboxes(updatedDropdownCheckboxes);
  };
  

  const handleCheckboxChange = (index: number) => {
    const updatedDropdownCheckboxes = dropdownCheckboxes.map((item, i) =>
      i === index ? { ...item, isChecked: !item.isChecked } : item
    );
    setDropdownCheckboxes(updatedDropdownCheckboxes);
  };

  const addDropdownCheckbox = () => {
    setDropdownCheckboxes([
      ...dropdownCheckboxes,
      { dropdownValue: '', isChecked: false },
    ]);
  };

  const handleRemoveDropdownCheckbox = (index: number) => {
    const updatedDropdownCheckboxes = dropdownCheckboxes.filter((_, i) => i !== index);
    setDropdownCheckboxes(updatedDropdownCheckboxes);
  };

  const handleCommitScenario = () => {
    const name = input1;
    const description = input2;
  
    // Construire les fichiers en fonction de l'interface File
    const files: File[] = dropdownCheckboxes
      .filter((item) => item.dropdownValue) // Filtre les cases cochées avec des valeurs
      .map((item) => ({
        id: item.id, // Utilise l'ID associé
        isNeeded: item.isChecked, // La case est cochée
        name: item.dropdownValue, // Nom du fichier
      }));
  
    if (!name || !description || files.length === 0) {
      alert(translate('Veuillez remplir tous les champs requis.',lang));
      return;
    }
  
    const scenarioData = { name, description, files };
    ApiFile.PutScenario(scenarioData);
    navigate('/scenarios');
  };
  
  

  return (
    <div className='Newscenario-main-page'>
      <div className='Newscenarioheader'>
        <div className='Newscenario-left-header'>
          <div className='text-item'>
            <img src={backArrow} onClick={handleBackClick} alt="Back Arrow" className='back-arrow' />
          </div>
          <div className='text-item-header'>{translate("Nom du Scénarios",lang)}</div>
        </div>
        <div className='text-item'>
          <button className='check-button' onClick={handleCommitScenario}>
            <img src={check} alt="Check Icon" className='check-icon' />
          </button>
        </div>
      </div>

      <div className='Newscenario-form-container'>
        <input
          type="text"
          value={input1}
          onChange={(e) => setInput1(e.target.value)}
          placeholder={translate("Nom Du scénario",lang)}
          className="input-field"
        />
        <input
          type="text"
          value={input2}
          onChange={(e) => setInput2(e.target.value)}
          placeholder="Description"
          className="input-field"
        />

        {dropdownCheckboxes.map((item, index) => (
          <div key={index} className="dropdown-checkbox-container">
            <select
              value={item.dropdownValue}
              onChange={(e) => handleDropdownChange(index, e.target.value)}
              className="dropdown-field"
            >

              <option value="">{translate("Sélectionnez une option",lang)}</option>
              {types.map((type) => (
                <option key={type._id} id={type._id.toString()} value={type.nom}>
                  {type.nom.replace(/_/g, ' ')} {/* Remplace les underscores par des espaces */}
                </option>
              ))}
            </select>

            <label className="checkbox-container">
              <input
                type="checkbox"
                checked={item.isChecked}
                onChange={() => handleCheckboxChange(index)}
                className="checkbox-input"
              />
            </label>

            {/* Trash button */}
            <button onClick={() => handleRemoveDropdownCheckbox(index)} className="trash-button">
              <img src={trash} alt="Trash Icon" className="trash-icon" />
            </button>
          </div>
        ))}

        <button onClick={addDropdownCheckbox} className="plus-button">
          <img src={plus} alt="Plus Icon" className="plus-icon" />
        </button>
      </div>
    </div>
  );
}

// CSS styles (assuming you place these in an accompanying CSS or SCSS file)
