//login 

import React from 'react'
import ResetPasswordBox from '../components/resetPasswordbox';
import logo from '../icon/Alfred_lineWhite.png'

export default function ResetPassword() {
  return (
    <div className="login-container">
      <img
        src={logo}
        alt="Login Image"
        className="login-image"
      />
      <ResetPasswordBox  /> {/* Utiliser LoginBox ici */}
    </div>
  );
}