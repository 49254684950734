import React, { useState, useEffect } from 'react';
import CardScenarios from '../../components/CardScenarios';
import { ApiFile } from '../../api/ApiFile';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../components/language/LanguageContext';
import translate from '../../components/language/Translate';

export default function Index() {
  const { lang, changeLanguage } = useLanguage();

  const [scenarios, setScenarios] = useState<any[]>([]);
  
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/scenarios/nouveau');
  };

  useEffect(() => {
    const fetchScenario = async () => {
      try {
        // Fetch scenarios data from the API
        const response = await ApiFile.getSenario();

        // Assuming the API response contains two properties: adminScenarios and personalScenarios
        const { adminScenarios, personalScenarios } = response;
        console.log(adminScenarios)
        // Format admin scenarios
        const formattedAdminScenarios = adminScenarios.map((scenario: any) => ({
          title: scenario.name,
          description: scenario.description,
          image: `https://alfred-app.fr/scenario/${scenario.id}.jpg`, // Admin scenario image
          id: scenario.id,
        }));

        // Format personal scenarios with placeholder image
        const formattedPersonalScenarios = personalScenarios.map((scenario: any) => ({
          title: scenario.name,
          description: scenario.description,
          image: 'https://via.placeholder.com/532x226', // Personal scenario image
          id: scenario._id,
        }));

        // Combine both formatted admin and personal scenarios
        const allScenarios = [
          ...formattedAdminScenarios,
          ...formattedPersonalScenarios,
        ];
        console.log(allScenarios)
        // Set the scenarios state with the formatted data
        setScenarios(allScenarios);
      } catch (error) {
        console.error('Error fetching scenarios:', error);
      }
    };

    fetchScenario();
  }, []); // Empty dependency array ensures the effect runs only once on component mount


  // scenarios = [
  //   {
  //     title: 'Inscription EPITECH (personnalisé)',
  //     description: 'Description for scenario 1.',
  //     image: 'https://via.placeholder.com/532x226'  // Example image URL
  //   },
  //   {
  //     title: 'Effectuer un changement d’adresse',
  //     description: 'Description for scenario 2.',
  //     image: 'https://via.placeholder.com/532x226'  // Example image URL
  //   },
  //   {
  //     title: 'Demande de prêt bancaire',
  //     description: 'Description for scenario 3.',
  //     image: 'https://via.placeholder.com/532x226'  // Example image URL
  //   },
  //   {
  //     title: 'Renouveler sa carte d’identité',
  //     description: 'Description for scenario 4.',
  //     image: 'https://via.placeholder.com/532x226'  // Example image URL
  //   },
  //   // Add more scenarios as needed to test scrolling
  // ];

  return (
    <div className='index-scenario-main-page'>
      <div className='index-scenario-page'>
        <div className='index-scenario-top-page'>
          <div className='page-title'>{translate("Scénarios",lang)}</div>
          <div onClick={handleBackClick} className='index-scenario-add-button'>{translate("Créer un nouveau Scénarios",lang)}</div>
        </div>
        {/* Wrap CardScenarios in a scrollable container */}
        <div className="scenarios-container">
          <CardScenarios scenarios={scenarios} />
        </div>
      </div>
    </div>
  );
}
