import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../icon/Alfred_lineWhite.png';
import France from '../icon/france.svg';
import English from '../icon/united.svg';
import { useLanguage } from '../components/language/LanguageContext';
import translate from '../components/language/Translate';

function Disclaimer() {
    const { lang, changeLanguage } = useLanguage();
  const navigate = useNavigate();

  const handleFrenchLanguage = () => {
    console.log("fr");
    changeLanguage("fr");
};

const handleEnglishLanguage = () => {
    console.log("en");
    changeLanguage("en");
};
  const handleButtonClick = () => {
    navigate('/login');
  };

  return (
    <div className='disclaimer-container'>
      {/* Language Buttons */}
      <div className='language-buttons'>
        <button className='language-button' onClick={handleFrenchLanguage}>
          <img src={France} alt='France' />
        </button>
        <button className='language-button' onClick={handleEnglishLanguage}>
          <img src={English} alt='United Kingdom' />
        </button>
      </div>

      <img src={logo} alt='Logo' className='disclaimer-image' />
      <div className='disclaimer-box'>
        <h1 className='disclaimer-title'>{translate('Conditions générales',lang)}</h1>
        <p className='disclaimer-text'>
        {translate(`disclaimer-text`,lang)}
        </p>
        <button onClick={handleButtonClick}>{translate('confirmation',lang)}</button>
      </div>
    </div>
  );
}

export default Disclaimer;
